import React from 'react'
import PropTypes from "prop-types";
import {connect} from "react-redux";
import * as Cookies from "js-cookie";
import UserHeader from '../Parts/Header/Header-User';
import GuestHeader from '../Parts/Header/Header-Guest';
import socket from "../../../Socket";
import {Event, decode, encode, wait, isMobile, SITE, sendNotfication} from "../../../Helper";
import C from "../../../Constant";
import Login from "../Auth/Login";
import storage from "../../../Storage";
import Help from '../Parts/Sidebar/Help';
import Terms from '../Parts/Sidebar/Terms';
import Engine from "../../Games/Crash/Engine";

import Footer from '../Parts/Footer';
import Mobi from "../../Games/Crash/Graphic/Mobi";
import WalletAlert from "../../Components/User/Wallet/Alert";
import Canvas from "../../Games/Crash/Graphic";
import HistoryList from "../../Games/Crash/includes/HistoryList";
import Bet from "../../Games/Crash/Bet";
import Queue from "../../Games/Crash/Queue";
import AllBets from "../../Games/Crash/Queue2";
import {Table} from "react-bootstrap";

class Referral extends React.Component {
    _Mounted = false;
    constructor(props){
        super(props);
        this.state = {
            mobile:false,
            isLogged: false,
            token: false,
            userid: storage.getKey("uid"),
            aff_balance: storage.getKey("aff_balance"),
            header: <UserHeader t={this.props.t} location={this.props.location} />,
            auth: false
        }
        this.handleResize = this.handleResize.bind(this);
    }
    componentWillMount() {
        wait(500).then(() => {
            this._Mounted = true;
        })
    }

    componentDidMount() {
        wait(1700).then(() => {
            this.setState({ loading: false });
        })
        console.log("Fetching referrals data");
        socket.emit(C.MY_AFF, encode({token: storage.getKey('token')}));
        socket.on(C.MY_AFF, data => this.makeList(decode(data)));
        socket.on(C.MY_AFF_TO_WALLET, data => this.withdrawalResponse(decode(data)));
        socket.on(C.MY_AFF_TO_MPESA, data => this.withdrawalResponse(decode(data)));

        this.intervalId = setInterval(() => {
            this.setState({
                aff_balance: storage.getKey("aff_balance")
            });
            // console.log("aff balance: " + storage.getKey("aff_balance"));
        }, 5000);
    }
    updateState = (data) => {
        this.setState({aff_balance: storage.getKey("aff_balance")})
    }
    makeList = (data) => {
        console.log(data)
        this.setState({ loading: false, list: data.referrals });
    }
    withdrawalResponse = (data) => {
        sendNotfication('Your request is being processed', 'info', 'top-center');

    }
    copy(){
        var copyText = document.getElementById("myInput");

        // Select the text field
        copyText.select();
        copyText.setSelectionRange(0, 99999); // For mobile devices

        // Copy the text inside the text field
        navigator.clipboard.writeText(copyText.value);
        // navigator.clipboard.writeText(txtx.value);
    }

    withdrawToMpesa(){
        socket.emit(C.MY_AFF_TO_MPESA, encode({token: storage.getKey('token'), amount: storage.getKey("aff_balance"), uid: storage.getKey("uid")}));
    }
    withdrawToWallet(){
        socket.emit(C.MY_AFF_TO_WALLET, encode({token: storage.getKey('token'), amount: storage.getKey("aff_balance"), uid: storage.getKey("uid")}));
    }

    getWidth(){
        return document.documentElement.clientWidth || document.body.clientWidth;
    }
    handleResize(){
        if(this.getWidth() < 1540)
        {
            this.setState({ col: 'col-xl-12'});
            Event.emit('hide_games');
        }
        else {
            this.setState({ col: 'col-xl-9'});
            Event.emit('show_min_games');
        }

        if(isMobile()){

            this.setState({ mobile: true });
        }
        else {
            this.setState({ mobile: false });
        }

        if(isMobile()){
            this.setState({ padding: 'p-0', ovh: 'ovh' });
        }
    }
    render(){
        let { header, auth, mobile, userid, aff_balance } = this.state;
        let { content } = this.props; // Pages / Games Contents
        let wallet; // Show Wallet if User don't Have Cash
        let total_earnings = 0;

        try {
            wallet = this.props.get_wallet.show;
        }
        catch (e) {}

        const { t } = this.props;
        const tt = this.state.list?.forEach((item) => {
            total_earnings = total_earnings + item.aff_amount;
        })
        const list = this.state.list?.map((row, i) =>
            <List key={i} row={row} name={row.name} index={i+1} date={row.created} refff={row.id} amount={row.aff_amount} />
        );
        return(
            <>
                <div className="page-wrapper">

                    <div className="page-content container py-1 px-1" id={'page-content'}>
                        {header}

                        {wallet &&
                        <WalletAlert t={t} uid={this.props.get_wallet.uid} />
                        }
                        {auth === true &&
                        <Login t={t} justModal="true" />
                        }
                        <div className=' row m-0 p-0 mb-1'>
                            <div className='col-md-12 pl-1 pr-1  no-padding-on-mobile'>

                                <div className={'content-section text-center mb-2 p-0 ov-0'}>
                                    <img className="d-block w-100" src="/assets/images/banners/slider5.jpg"/>
                                </div>
                                <div className={'content-section text-center mb-2 p-2'}>
                                    <h2 className={'title-text-static'}>Referral Link</h2>
                                    <div>Copy and Share your referral link below</div>

                                    <div className="input-group mb-3">

                                        <input id={"myInput"} onChange={this.handleChange} style={{background: "#333333!important"}}
                                               type="text" value={SITE + '/aff/' + userid} className="form-control"
                                        />
                                        <div className="input-group-append" onClick={this.copy}>
                                            <span className="input-group-text text-heavy"  id="basic-ad1">Copy!</span>
                                        </div>
                                    </div>
                                </div>

                                <div className={'content-section text-center mb-2 p-2'}>
                                    <h2 className={'title-text-static'}>Referral Account</h2>
                                    {/*<div>Copy and Share your referral link below</div>*/}
                                    <div className={'row'}>
                                        <div className={'col-6'}>
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"
                                                          id="basic-addon1">Referrals</span>
                                                </div>
                                                <input type="text" onChange={this.handleChange}
                                                       value={this.state.list?.length} className={'form-control'}
                                                       style={{background: "#333333!important"}}/>
                                            </div>


                                        </div>
                                        <div className={'col-6'}>
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"
                                                          id="basic-add1">Earnings</span>
                                                </div>
                                                <input type="text" onChange={this.handleChange} value={total_earnings}
                                                       className={'form-control'}
                                                       style={{background: "#333333!important"}}/>
                                            </div>
                                        </div>


                                    </div>
                                    <div className={'row'}>
                                        <div className={'col-12'}>
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"
                                                          id="basic-add1">Balance</span>
                                                </div>
                                                <input type="text" onChange={this.handleChange} value={aff_balance}
                                                       className={'form-control'}
                                                       style={{background: "#333333!important"}}/>
                                            </div>
                                        </div>
                                        <div className={'col-md-12'}>
                                            <div className="btn-group  btn-group-aff w-100" role="group"
                                                 aria-label="Basic example">
                                                <button type="button" onClick={this.withdrawToWallet}
                                                        className="btn btn-success btn-walllet">Withdraw to
                                                    Wallet
                                                </button>
                                                <button type="button" onClick={this.withdrawToMpesa}
                                                        className="btn btn-success btn-mpesa">Withdraw to
                                                    M-PESA
                                                </button>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div className=' row m-0 p-0 mb-2'>
                                    <div className='col-md-12 m-0 p-0 '>
                                        <div className='content-section  h-100'>
                                            {
                                                this.state.loading ?
                                                    <div className="text-center">
                                                        <div class="spinner-border text-info my-3" role="status"/>
                                                    </div>
                                                    :
                                                    <>
                                                        {list?.length === 0 ?
                                                            <>
                                                                You have not introduced anyone yet
                                                            </>
                                                            :
                                                            <Table className={"mb-2"}>
                                                                <thead>
                                                                <tr>
                                                                    <th>
                                                                        #
                                                                    </th>
                                                                    <th>
                                                                    Ref
                                                                    </th>
                                                                    <th>
                                                                        Username
                                                                    </th>
                                                                    <th>
                                                                        Earnings
                                                                    </th>
                                                                    <th>
                                                                        Date Registered
                                                                    </th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {list}
                                                                </tbody>
                                                            </Table>
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
class List extends React.Component {

    render(){
        let { index, name, date, refff, amount } = this.props;
        return(
            <tr>
                <td>
                    {index}
                </td>
                <td>
                    {refff}
                </td>
                <td>
                    {name}
                </td>
                <td>
                    {amount}
                </td>
                <td>
                    {date}
                </td>

            </tr>
        );
    }
}

export default Referral;


