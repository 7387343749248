import React from 'react'
import PropTypes from "prop-types";
import {connect} from "react-redux";
import * as Cookies from "js-cookie";
import UserHeader from './Parts/Header/Header-User';
import GuestHeader from './Parts/Header/Header-Guest';
import Sidebar from './Parts/Sidebar';
import Footer from './Parts/Footer';
import socket from "../../Socket";
import {Event, decode, encode, wait, isMobile} from "../../Helper";
import WalletAlert from "../../App/Components/User/Wallet/Alert";
import C from "../../Constant";
import Login from "./Auth/Login";
import storage from "../../Storage";
import Faqs from './Parts/Sidebar/FAQs_';
import Help from './Parts/Sidebar/Help';
import Terms from './Parts/Sidebar/Terms';
import Engine from "../Games/Crash/Engine";
import Canvas from "../Games/Crash/Graphic/";
import Mobi from "../Games/Crash/Graphic/Mobi";
import Bet from "../Games/Crash/Bet";
import Queue from "../Games/Crash/Queue";
import AllBets from "../Games/Crash/Queue2";


import HistoryList from "../Games/Crash/includes/HistoryList";

class Index extends React.Component {
    _Mounted = false;
    constructor(props){
        super(props);
        this.state = {
            mobile:false,
            isLogged: false,
            token: false,
            header: <GuestHeader t={this.props.t} location={this.props.location} />,
            auth: false
        }
        this.handleResize = this.handleResize.bind(this);
    }
    componentWillMount() {
        wait(500).then(() => {
            this._Mounted = true;
        })
    }

    componentDidMount(){
        socket.on(C.ONLINE, (status) => this.loginUser(decode(status)));
        Event.on('showAuthModal', (status) => this.activeAuth(status));

        /**
         * Initialize Authentication
         */
        const sessionCookie = Cookies.get("auth");

        if(storage.getKey('token') !== null && sessionCookie)
        {
            socket.emit(C.ONLINE, encode({
                jwt_token: storage.getKey('jwt_token'), //fake
                user_token: storage.getKey('user_token'), //fake
                security_key: storage.getKey('security_key'), //fake
                secret_user: storage.getKey('secret_user'), //fake
                secret_realtime: storage.getKey('secret_realtime'), //fake
                client_system: storage.getKey('client_system'), //fake
                token_key: storage.getKey('token_key'), //fake
                secret_token: storage.getKey('secret_token'), //fake
                token: storage.getKey('token'), // REAL
            }));
        }
        wait(600).then(() => {
            this.handleResize();
        });
        window.addEventListener('resize', this.handleResize);

        if(storage.getKey('token')){
            this.setState({ isLogged: true, token: storage.getKey('token') });
        }

        wait(500).then(() => {
            Engine.getStatus();
        });
    }

    activeAuth = (status) => {
        this.setState({ auth: status });
    }

    loginUser = (data) => {
        wait(500).then(() => {
            // console.log('Log in user data', data)
            if(data.status === true)
            {
                this.setState({ header: <UserHeader t={this.props.t} location={this.props.location} /> });
                Cookies.set("uid", data.id, {expires: 14});
                Cookies.set("auth", true, {expires: 14});
                // storage.setKey('name', data.name);
                // storage.setKey('email', data.email);
                // storage.setKey('phoneNumber', data.phoneNumber);
                // storage.setKey('credit', data.credit);
                // storage.setKey('avatar', data.avatar);
                // storage.setKey('friends', data.friends);
                // storage.setKey('room', data.room);
            }
            else {
                wait(7000).then(() => {
                    localStorage.clear();
                })
            }
        })
    }
    getWidth(){
        return document.documentElement.clientWidth || document.body.clientWidth;
    }
    handleResize(){
        if(this.getWidth() < 1540)
        {
            this.setState({ col: 'col-xl-12'});
            Event.emit('hide_games');
        }
        else {
            this.setState({ col: 'col-xl-9'});
            Event.emit('show_min_games');
        }

        if(isMobile()){
            
            this.setState({ mobile: true });
        }
        else {
            this.setState({ mobile: false });
        }

        if(isMobile()){
            this.setState({ padding: 'p-0', ovh: 'ovh' });
        }
    }
    render(){
        let { header, auth, mobile } = this.state;
        let { content } = this.props; // Pages / Games Contents
        let wallet; // Show Wallet if User don't Have Cash
        let { game } = this.props;
        try {
            wallet = this.props.get_wallet.show;
        }
        catch (e) {}
        const engine = Engine;
        const { t } = this.props;
        return(
            <>
                
                <div className="page-wrapper">
                
                    <div className="page-content container py-1 px-1" id={'page-content'}>
                    {header}

                        {wallet &&
                            <WalletAlert t={t} uid={this.props.get_wallet.uid} />
                        }
                        {auth === true &&
                            <Login t={t} justModal="true" />
                        }
                        <div className=' row m-0 p-0 mb-1'>
                            <div className='col-md-8 pl-1 pr-1  no-padding-on-mobile'>

                                <div className='banner-wrap mb-2 '>
                                    <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <img className="d-block w-100" src="/assets/images/banners/slider1.jpg"/>
                                            </div>
                                            <div className="carousel-item">
                                                <img className="d-block w-100" src="/assets/images/banners/slider2.jpg"/>
                                            </div>
                                            <div className="carousel-item">
                                                <img className="d-block w-100" src="/assets/images/banners/slider3.jpg"/>
                                            </div>
                                            <div className="carousel-item">
                                                <img className="d-block w-100" src="/assets/images/banners/slider4.jpg"/>
                                            </div>
                                            <div className="carousel-item">
                                                <img className="d-block w-100" src="/assets/images/banners/slider5.jpg"/>
                                            </div>
                                            <div className="carousel-item">
                                                <img className="d-block w-100" src="/assets/images/banners/slider6.jpg"/>
                                            </div>


                                        </div>
                                        <a className="carousel-control-prev" href="#carouselExampleControls"
                                           role="button" data-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                        <a className="carousel-control-next" href="#carouselExampleControls"
                                           role="button" data-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="sr-only">Next</span>
                                        </a>
                                    </div>
                                    {/* <img class="d-block w-100" src="/assets/images/banners/stake.png" alt="First slide"/> */}
                                </div>
                                <div className='m-0 p-0 show-mobile'>

                                    <Mobi mobile={this.state.mobile}/>

                                    {/* <div className='history_ mb-2'>

                        </div> */}
                                </div>
                                <div className=' row m-0 p-0 mb-2'>
                                    <div className='col-md-7 m-0 p-0 hide-mobile pr-1'>
                                        <div className='content-section  h-100'>
                                            <Canvas mobile={this.state.mobile}/>
                                            <HistoryList game={'crash'} t={this.props.t}/>
                                            {/* <Queue t={this.props.t}/> */}
                                        </div>

                                    </div>

                                    <div className='col-md-5 m-0 py-0 pr-0 pl-1 gamer'>


                                        <div className='content-section p-2'>
                                            {/* <Help t={t}/> */}
                                            <Bet mobile={this.state.mobile} token={this.state.token}
                                                 isLogged={this.state.isLogged}/>
                                        </div>

                                    </div>

                                </div>

                                <Queue t={this.props.t} className='d-sm-none'/>
                            </div>

                            <div className='col-lg-4 col-md-4 col-sm-0 pl-1 pr-1'>
                                {/* <Queue t={this.props.t}/> */}
                                <AllBets engine={engine} t={this.props.t} game={game}/>
                            </div>

                        </div>


                        <div className='row footer-card m-0 p-0 mt-1 text-lightt justify-content-center'>


                            <div className='col-md-8 mb-2'>
                                <div className='text-center'>
                                    {/* <img src="/assets/images/logo.png" className="logo-sm" alt="Logo" /> <br></br> */}
                                    {/* <div className='text-center'>
                                <img src="/assets/images/18.png" className="logo-smm ulogo" alt="Logo" /> <br></br>
                                </div>
                                 */}
                                    {/* <div className='mb-0 text-center'>GAMBLING CAN BE ADDICTIVE. PLEASE PLAY RESPONSIBLY</div> */}
                                    {/* <div className='mb-2 text-center'>SHUJAA FORTUNE is licensed and regulated by the Betting Control and Licensing Board of Kenya (BCLB).</div> */}
                                    {/* <div className='row'>
                                     <div className='col-md-6 footy text-right'>
                                        <div className='op-8'>&copy; 2022 All Rights Reserved</div>
                                        <div> <Faqs t={t} /> | <Terms t={t}/> </div>
                                     </div>

                                     <div className='col-md-6 footy text-left'>
                                     <ul className='no-style-list social-list op-8'>
                                        <li>
                                            <a href='tel:0716334455'> Call/Whatsapp 0716334455 </a>
                                        
                                        </li>
                                        
                                        <li>
                                            <a href='mailto:info@suq.co.ke'> E-mail: info@suq.co.ke </a>
                                        
                                        </li>
                                       
                                    </ul>
                                     </div>
                                </div> */}
                               
                               licensed by BCLB (Betting Control and Licensing Board of Kenya) under the Betting, Lotteries and Gaming Act, Cap 131, Laws of Kenya. <br></br>
                               Must be 18 years of age or older to register or play at Nextpapi. Play responsibly.
                                    <a href='tel:'> Call/Whatsapp  </a>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                    
                </div>
            </>
        );
    }
}

Index.propTypes = {
    get_wallet: PropTypes.string
};

const mapStateToProps = state => ({
    get_wallet: state.items.get_wallet
});

export default connect(mapStateToProps, null)(Index);